import matchtitle from '../../components/matchtitle';
import {

	Message, Loading
} from 'element-ui'
import {
	handlePageAuthPlayer,
	handlePageQueryWaitAuthplayer,
	handlePayplayerauth,
	handleQueryMatchRelateSet,
	handleAuthPlayersByOrganizerPayFee
} from '@index/api/joinmatch/realnameauth';
export default {
	name: 'realnameauth',
	components: {
		matchtitle
	},
	data() {
		return {
			authPalyers: 0,
			unAuthPalyers: 0,
			payDialogVisible: false,
			pageSizeWaitAuthPlayer: 50,
			currPageWaitAuthPlayer: 1,
			totalCountWaitAuthPlayer: 0,
			playerDialogVisible: false,
			playersData: [],
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			playerIds: [],
			matchId: sessionStorage.getItem('joinMatchId'),
			playersCount: 0,
			totalFee: 0,
			QRcode: "",
			matchRelateSet: {}
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageAuthPlayer({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				realNameAuthStatus: this.queryForm.realNameAuthStatus,
				matchId: this.matchId
			}).then(res => {
				if (res.status == 200) {
					var tempArr = [];
					// for(var i=0;i<100;i++){
					// 	tempArr=tempArr.concat(res.data);
					// }
					tempArr = res.data;
					this.tableData = tempArr;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.authPalyers = res.extendData.authPalyers;
					this.unAuthPalyers = res.extendData.unAuthPalyers;

					this.$nextTick(() => {
						this.$refs.table1.bodyWrapper.scrollTop = 0
					})
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		selectInit(row) { //判断组别是否可选择
			if (row.selectFlag == '1') {
				return false //不可勾选
			} else {
				return true //可勾选
			}
		},
		//选择待认证选手******************************************************************
		handleSizeChangeWaitAuthPlayer(pageSize) { //改变页面大小
			this.pageSizeWaitAuthPlayer = pageSize;
			this.pageQuery();
		},
		handleCurrentChangeWaitAuthPlayer(currPage) { //跳转到某一页
			this.currPageWaitAuthPlayer = currPage;
			this.pageQuery();
		},
		// fastQueryWaitAuthPlayer() {
		//   this.currPageWaitAuthPlayer = 1;
		//   this.pageQuery();
		// },
		pageQueryWaitAuthPlayer(type) {
			var reqParams = {
				pageSize: this.pageSizeWaitAuthPlayer,
				currPage: this.currPageWaitAuthPlayer,
				matchId: this.matchId,
				playerIds: [],
			}
			if ("2" == type) { //根据选择选手认证
				reqParams.playerIds = this.playerIds;
			}

			handlePageQueryWaitAuthplayer(reqParams).then(res => {
				if (res.status == 200) {
					var tempArr = [];
					// for(var i=0;i<100;i++){
					// 	tempArr=tempArr.concat(res.data);
					// }
					tempArr = res.data;
					this.playersData = tempArr;
					this.currPageWaitAuthPlayer = res.currPage;
					this.pageSizeWaitAuthPlayer = res.pageSize;
					this.totalCountWaitAuthPlayer = res.totalCount;
					this.totalFee = res.extendData.totalFee;
					this.playersCount = res.extendData.playersCount;

					this.$nextTick(() => {
						this.$refs.playersTable.bodyWrapper.scrollTop = 0
					})

				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		selectPlayers(selection) { //选择选手			
			this.playerIds = [];
			if (selection && selection.length > 0) {
				selection.forEach((item) => {
					this.playerIds.push(item.id);
				});
			} else {
				this.playerIds = [];
			}
		},
		openWaitAuthPlayerDialog(type) { //打开待认证选手对话框
			this.playerDialogVisible = true;
			this.queryMatchRelateSet();
			if ("1" == type) { //一键认证
				this.playerIds = [];
				this.pageQueryWaitAuthPlayer("1");
			} else { //认证选择选手
				this.pageQueryWaitAuthPlayer("2");
			}
		},
		nextStep() { //下一步进入支付
			this.payDialogVisible = true;
			handlePayplayerauth({
				playerIds: this.playerIds,
				matchId: this.matchId
			}).then(res => {
				let bytes = new Uint8Array(res);
				let storeData = "";
				let len = bytes.byteLength;
				for (let i = 0; i < len; i++) {
					storeData += String.fromCharCode(bytes[i]);
				}
				this.QRcode = "data:image/png;base64," + window.btoa(storeData);
			})
		},
		queryMatchRelateSet() {//比赛设置
			handleQueryMatchRelateSet(this.matchId).then(res => {
				this.matchRelateSet = res.data;
			});
		},
		authPlayersByOrganizerPayFee() {//通过主办方支付费用认证选手			
			handleAuthPlayersByOrganizerPayFee({
				matchId: this.matchId,
				playerIds: this.playerIds
			}).then(res => {
				var _this = this;
				// var loading = this.$loading({
				// 	lock: true,
				// 	text: '认证中......',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				if (res.status == 200) {
					this.$alert(res.msg, {
						confirmButtonText: '确定',
						callback: action => {
							_this.playerDialogVisible = false;
							_this.pageQuery();
						}
					});

					// Message({
					// 	duration: 0,
					// 	showClose: true,
					// 	center: false,
					// 	offset: 300,
					// 	message: res.msg,
					// 	type: 'success',
					// 	onClose: function () {
					// 		_this.playerDialogVisible = false;
					// 		_this.pageQuery();
					// 	}
					// })


				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}

			});
		},
		closePayDialogEvent() {
			var _this = this;
			this.$alert('支付成功后，预计1~2分钟后得到认证结果，请耐心等待!', {
				confirmButtonText: '确定',
				callback: action => {
					_this.playerDialogVisible = false;
					_this.payDialogVisible = false;
					_this.pageQuery();
				}
			});
		}
	}
}