<!--比赛标题-->
<template>
	<div class="match-title"><i class="dot"></i>{{title}}</div>
</template>

<script>
	import {

		Message
	} from 'element-ui'
	import {
		handleStreamlineDetails

	} from '@index/api/joinmatch/matchinfo';
	export default {
		name: 'matchtitle',
		components: {

		},
		data() {
			return {
				title: '',
				matchId: sessionStorage.getItem('joinMatchId'),
			};
		},
		watch: {

		},
		created() {
			this.matchdetails();

		},
		mounted() {

		},
		destroyed() {

		},
		methods: {

			matchdetails() { //比赛详情
				handleStreamlineDetails(this.matchId).then(res => {
					if (res.status == 200) {
						this.title = res.data.matchName
					} else {
						Message({
							type: 'error',
							message: res.msg,
							uration: 5000
						});
					}
				});
			}

		}
	}
</script>

<style lang="scss">
	// .match-title {
	//   height: 60px;
	//   line-height: 60px;
	//   font-size: 16px;
	//   background: white;
	//   border-bottom: solid ghostwhite 4px;
	//   box-shadow: ghostwhite 10px 10px 30px 5px; //边框阴影;
	//   padding-left: 20px;
	//   .dot {
	//     width: 10px;
	//     height: 10px;
	//     background: #F32C6C;
	//     border-radius: 50%;
	//     display: inline-block;
	//     margin-right: 10px;
	//   }
	// }  
	.match-title {
		height: 2.3rem;
		line-height: 2.3rem;
		font-size: 1.05rem !important;
		background: white;
		border-bottom: solid white 4px;
		padding-left: 20px;
		
			
		i {
			color: #F32C6C;
			margin-right: 15px;
			clear: both;
		}

		.dot {
			width: 10px;
			height: 10px;
			background: #F32C6C;
			border-radius: 50%;
			display: inline-block;
			margin-right: 10px;
		}
	}
</style>