import request from '@index/utils/request'

export function handlePageAuthPlayer(data) { //分页查询实名认证选手
  return request({
    url: 'realnameauth/pageAuthPlayer',
    method: 'post',
    data
  })
}


export function handlePageQueryWaitAuthplayer(data) { //分页查询待认证选手
  return request({
    url: 'realnameauth/pageQueryWaitAuthplayer',
    method: 'post',
    data
  })
}


export function handlePayplayerauth(data) { //分页查询待认证选手
  return request({
    url: 'payplayerauth/pay',
    method: 'post',
     responseType: "arraybuffer",  
    data
  })
}


export function handleQueryMatchRelateSet(matchId) {//查询赛事相关设置
  return request({
    url: 'matchrelateset/queryMatchRelateSet',
    method: 'post',
    params:{matchId:matchId}
  })
}

export function handleAuthPlayersByOrganizerPayFee(data) { //通过主办方支付费用认证选手
  return request({
    url: 'realnameauth/authPlayersByOrganizerPayFee',
    method: 'post',
    data
  })
}



